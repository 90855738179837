import qs, { ParsedQs } from 'qs';
import { loginLink } from '@/const/serviceEnv';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * 获取路径query参数
 */
export const getQuery = (): ParsedQs =>
  qs.parse(typeof window !== 'undefined' ? window.fleLocation.search.substring(1) : '');

/**
 * 金额格式化
 * @param data
 */
type moneyType = number | string;

interface ShowMoneyType {
  (data: moneyType): string;
}
export const showMoney: ShowMoneyType = (data) => (+data / 100).toFixed(2);

/**
 * 匹配登录门户的loginLink
 */
export const getLoginLink = (code?: string) => {
  return code ? `${loginLink}/${code}` : loginLink;
};

export function getMainDomain(): string {
  if (typeof window !== 'undefined') {
    const { hostname } = window.fleLocation;
    const parts = hostname.split('.').reverse();

    // parts[0] 是顶级域名（例如，'com'），parts[1] 是主域名（例如，'google'）
    if (parts && parts[1]) {
      return `${parts[1]}.${parts[0]}`;
    }
    return hostname;
  }

  return '';
}

const TZ = 'Asia/Shanghai';
const FORMAT = 'YYYY-MM-DD HH:mm:ss';

function toChineseTime(time?: string) {
  return new Date(dayjs(time).tz(TZ).format(FORMAT));
}

export function dateBetween(start: string, end: string, date?: string) {
  const _start = new Date(start).getTime();
  const _end = new Date(end).getTime();
  const now = toChineseTime(date).getTime();
  return now >= _start && now <= _end;
}