import { LockOutlined, MobileOutlined, UserOutlined, KeyOutlined } from '@ant-design/icons';
import type { FormRule } from 'antd';

export const LoginTypes = {
  SUPPLIER: '40',
  SMART_CARP: '90',
  // WANLI: '100', // 废弃
  WOWCARP: '120', // 万鲤商联（原妙鲤）
  CUSTOMER_SERVICE: '140',
  WOWCARP_ADMIN: '150',
  SMART_CARP_ADMIN: '160',
  FLE_AI: '170',
  GENERAL_MALL: '180',
  CUSTOM_MALL: '190',
  DISTRIBUTOR: '200',
  MC_MARKETING_MALL: '210',
} as const;

export type LoginType = (typeof LoginTypes)[keyof typeof LoginTypes];

export type LoginMode = 'account' | 'mobile';

type FormItemType = 'account' | 'mobile' | 'password' | 'code';

type FormItemConfig = {
  [key in FormItemType]?: {
    name: string;
    minLength?: number;
    maxLength?: number;
    prefix?: React.ReactNode;
    placeholder?: string;
    rules?: FormRule[];
  };
};

export interface ApplicationItem {
  loginType?: LoginType;
  logo?: string;
  title?: string;
  systemId?: any;
  tokenKey?: string;
  setDomin?: boolean;
  showRegister?: boolean;
  showForgetPwd?: boolean;
  loginStep?: number;
  loginApi?: {
    account?: string;
    mobile?: string;
  };
  loginMode?: Array<{
    key: LoginMode;
    label: string;
  }>;
  formItemConfig?: Partial<FormItemConfig>;
  registerBanner?: string;
  userHref?: string;
  serviceHref?: string;
  privacyHref?: string;
  styleToken?: {
    themeColor?: string;
  };
  defaultLoginPath?: string;
  className?: string;
  defaultParams?: {
    [key: string]: any;
  };
  checkLoginErrorModalConfig?: {
    banner: string;
    logo: string;
  };
  showCreateCompany?: boolean;
  registerText?: string;
  showInviterId?: boolean;
  forgetPasswordType?: 'mobile' | 'account'; // 找回密码，验证账号的方式
}

type ApplicationProps = {
  [key in LoginType]?: ApplicationItem;
};

const DefaultCheckLoginErrorModalConfig = {
  banner: 'https://oss.elebuys.com/202408071812360009908319.png',
  logo: 'https://oss.elebuys.com/202408071814150009914638.png',
};

const BaseConfig: Partial<ApplicationItem> = {
  tokenKey: 'token',
  loginStep: 2,
  setDomin: false,
  showRegister: true,
  showForgetPwd: true,
  loginApi: {
    account: 'loginAccount',
    mobile: 'loginMobile',
  },
  loginMode: [
    { key: 'account', label: '密码登录' },
    { key: 'mobile', label: '验证码登录' },
  ],
  formItemConfig: {
    account: {
      name: 'mobile',
      maxLength: 11,
      placeholder: '请输入手机号',
      prefix: <MobileOutlined className="fx__login-prefixIcon" />,
      rules: [
        { required: true, message: '请输入手机号' },
        { pattern: /^1\d{10}$/, message: '手机号格式错误' },
      ],
    },
    mobile: {
      name: 'mobile',
      maxLength: 11,
      placeholder: '请输入手机号',
      prefix: <MobileOutlined className="fx__login-prefixIcon" />,
      rules: [
        { required: true, message: '请输入手机号' },
        { pattern: /^1\d{10}$/, message: '手机号格式错误' },
      ],
    },
    password: {
      name: 'password',
      placeholder: '请输入密码',
      prefix: <LockOutlined className="fx__login-prefixIcon" />,
      rules: [{ required: true, message: '请输入密码' }],
    },
    code: {
      name: 'smsCode',
      maxLength: 6,
      placeholder: '请输入验证码',
      prefix: <KeyOutlined className="fx__login-prefixIcon" />,
      rules: [{ required: true, message: '请输入验证码！' }],
    },
  },
  logo: 'https://oss.elebuys.com/logo/carpdance/carpdance-logo.png',
  registerBanner: 'https://oss.elebuys.com/tmpdir/202311011942280003734108.png',
  serviceHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=618',
  userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=616',
  privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=617',
  checkLoginErrorModalConfig: DefaultCheckLoginErrorModalConfig,
};

const createConfig = (overrides: Partial<ApplicationItem>): ApplicationItem => ({
  ...BaseConfig,
  ...overrides,
});

const commonProps: ApplicationItem = {
  loginType: '40',
  title: '供应商平台',
  systemId: '100005',
  logo: 'https://oss.elebuys.com/logo/carpdance/carpdance-logo.png',
  tokenKey: 'supplier_token',
  loginStep: 1,
  setDomin: false,
  showRegister: false,
  showForgetPwd: true,
  userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=649',
  privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=650',
  loginApi: {
    account: 'supplierLogin',
  },
  loginMode: [],
  defaultParams: {
    authType: 1,
    platform: 3,
    customerType: '40',
  },
  formItemConfig: {
    account: {
      name: 'userName',
      maxLength: 36,
      placeholder: '请输入登录账号',
      prefix: <UserOutlined className="fx__login-prefixIcon" />,
      rules: [
        {
          required: true,
          message: '请输入登录账号',
        },
      ],
    },
    password: {
      name: 'password',
      placeholder: '请输入密码',
      prefix: <LockOutlined className="fx__login-prefixIcon" />,
      rules: [
        {
          required: true,
          message: '请输入密码',
        },
      ],
    },
  },
  checkLoginErrorModalConfig: DefaultCheckLoginErrorModalConfig,
};

const Application: ApplicationProps = {
  [LoginTypes.SUPPLIER]: commonProps,
  [LoginTypes.SMART_CARP]: createConfig({
    loginType: LoginTypes.SMART_CARP,
    title: '智鲤',
    systemId: '100001',
    showCreateCompany: true,
    logo: '//oss.elebuys.com/logo/smartercarp/smartercarp-logo.png',
    registerBanner: '//oss.elebuys.com/logo/smartercarp/registerBanner.png',
  }),
  // [LoginTypes.WANLI]: createConfig({
  //   loginType: LoginTypes.WANLI,
  //   title: '万鲤商联',
  //   systemId: '100003',
  //   logo: 'https://oss.elebuys.com/resource/elebuys/202401221352550000848728.png',
  //   registerBanner: 'https://oss.elebuys.com/resource/goods/materials/202401261508450002868346.png',
  //   serviceHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=614',
  //   userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=612',
  //   privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=613',
  // }),
  [LoginTypes.WOWCARP]: createConfig({
    loginType: LoginTypes.WOWCARP,
    title: '万鲤商联',
    systemId: '100005',
    showInviterId: true,
    showCreateCompany: true,
    logo: '//oss.elebuys.com/logo/carpunion/only-logo.png',
    registerBanner: '//oss.elebuys.com/logo/smartercarp/registerBanner.png',
    // userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=633',
    // privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=634',
    userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=649',
    privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=650',
    serviceHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=651',
  }),
  [LoginTypes.CUSTOMER_SERVICE]: createConfig({
    loginType: LoginTypes.CUSTOMER_SERVICE,
    title: '百鲤跃川客服平台',
    systemId: '100000',
    showRegister: false,
    formItemConfig: {
      account: {
        name: 'userName',
        maxLength: 36,
        placeholder: '请输入登录账号',
        prefix: <UserOutlined className="fx__login-prefixIcon" />,
        rules: [{ required: true, message: '请输入登录账号' }],
      },
      password: {
        name: 'password',
        placeholder: '请输入密码',
        prefix: <LockOutlined className="fx__login-prefixIcon" />,
        rules: [{ required: true, message: '请输入密码' }],
      },
    },
    loginMode: [{ key: 'account', label: '密码登录' }],
    loginApi: { account: 'panguLogin' },
  }),
  [LoginTypes.WOWCARP_ADMIN]: createConfig({
    loginType: LoginTypes.WOWCARP_ADMIN,
    title: '妙鲤运营后台',
    systemId: '100002',
    showRegister: false,
    logo: '//oss.elebuys.com/logo/carpunion/only-logo.png',
    registerBanner: '//oss.elebuys.com/logo/smartercarp/registerBanner.png',
    userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=633',
    privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=634',
  }),
  [LoginTypes.FLE_AI]: createConfig({
    loginType: LoginTypes.FLE_AI,
    title: '百鲤璇玑',
    systemId: '100006',
    showRegister: false,
    showCreateCompany: true,
    logo: '//oss.elebuys.com/logo/fle-ai/fle-ai-logo.png',
    registerBanner: 'https://oss.elebuys.com/resource/goods/materials/202408221707050009781844.png',
    styleToken: { themeColor: '#006ffd' },
    className: 'fle-ai',
    defaultLoginPath: '/module/ai/home',
    checkLoginErrorModalConfig: {
      banner: 'https://oss.elebuys.com/202408080959470000165133.png',
      logo: 'https://oss.elebuys.com/202408080959250000165121.png',
    },
    forgetPasswordType: 'account',
  }),
  [LoginTypes.GENERAL_MALL]: createConfig({
    loginType: LoginTypes.GENERAL_MALL,
    title: '通用分销商城',
    systemId: '100001',
    showRegister: false,
    showForgetPwd: true,
    loginStep: 2,
    setDomin: false,
    loginApi: {
      account: 'loginAccountMall',
      mobile: 'loginMobileMall',
    },
    loginMode: [{ key: 'account', label: '账号登录' }],
    logo: '//oss.elebuys.com/logo/smartercarp/smartercarp-logo.png',
    styleToken: { themeColor: '#5292ff' },
    defaultLoginPath: '/module/showroom/mall',
  }),
  [LoginTypes.CUSTOM_MALL]: createConfig({
    loginType: LoginTypes.CUSTOM_MALL,
    title: '自定义分销商城',
    systemId: '100008',
    logo: '//oss.elebuys.com/logo/smartercarp/smartercarp-logo.png',
    styleToken: { themeColor: '#FF412C' },
    defaultLoginPath: '/module/showroom/mall',
    userHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=649',
    privacyHref: 'https://store.fxqifu.com/front-end/#/article?store_article_id=650',
  }),
  [LoginTypes.DISTRIBUTOR]: createConfig({
    loginType: LoginTypes.DISTRIBUTOR,
    title: '智鲤分销商平台',
    systemId: '100009',
    showRegister: false,
    logo: '//oss.elebuys.com/logo/smartercarp/smartercarp-logo.png',
    styleToken: { themeColor: '#5292ff' },
  }),
  [LoginTypes.MC_MARKETING_MALL]: createConfig({
    loginType: LoginTypes.MC_MARKETING_MALL,
    title: '猫超动销宝',
    systemId: '100010',
    registerText: '员工账号注册',
    className: 'mc-dxb',
    logo: '//oss.elebuys.com/logo/mc/dx-only-logo.png',
    registerBanner: '//oss.elebuys.com/logo/mc/dx-register-banner.png',
    styleToken: { themeColor: '#00B45B' },
    userHref: 'https://oss.elebuys.com/resource/goods/materials/202411081805060005268260.pdf',
    privacyHref: 'https://oss.elebuys.com/resource/goods/materials/202411081804430005268258.pdf',
    checkLoginErrorModalConfig: {
      banner: 'https://oss.elebuys.com/operate/resource/202411112204220007439240.png',
      logo: 'https://oss.elebuys.com/operate/resource/202411112204050007439218.png',
    },
  }),
};

export default Application;
