export default {
  loginAccount: '/elebuys/user/login/v2/mobile/password', // 账号密码登录
  usersCheck: '/elebuys/member/users/check', // 手机号是否已注册
  companyCreate: '/elebuys/member/company/create', // 创建企业
  usersSignup: '/elebuys/member/users/signup', // 用户注册
  usersUpdatepwd: '/elebuys/member/users/updatepwd', // 修改密码
  companyIndustryList: '/elebuys/member/company/industry-list', // 行业列表
  mobilePassword: '/elebuys/user/login/v2/mobile/password', // 手机号密码登录
  mobileSmsCode: '/elebuys/user/login/v2/mobile/sms-code', // 手机号短信验证码登录
  employeeInfo: '/elebuys/user/login/v2/employee-info', // 查询当前用户绑定的员工及企业信息（智鲤）
  carpunionEmployeeInfo: '/elebuys/user/login/v2/employee-info-common', // 查询当前用户绑定的员工及企业信息（万鲤商联）
  wowcarpEmployeeInfo: '/elebuys/user/login/v2/employee-info-carp-dance', // 查询当前用户绑定的员工及企业信息（妙鲤）
  wowcarpAdminEmployeeInfo: '/elebuys/user/login/v2/employee-info-wow-carp-admin', // 查询当前用户绑定的员工及企业信息（妙鲤运营后台）
  fleaiEmployeeInfo: '/elebuys/user/login/v2/employee-info-xuanji', // 查询当前用户绑定的全部员工及企业信息（所有）- 璇玑
  enterCompany: '/elebuys/user/login/v2/enter-company', // 进入企业（智鲤）
  carpunionEnterCompany: '/elebuys/user/login/v2/enter-company-common', // 进入企业（万鲤商联）
  wowcarpEnterCompany: '/elebuys/user/login/v2/enter-company-carp-dance', // 进入企业（妙鲤）
  wowcarpAdminEnterCompany: '/elebuys/user/login/v2/enter-company-wow-carp-admin', // 进入企业（妙鲤运营后台）
  fleaiEnterCompany: '/elebuys/user/login/v2/enter-company-xuanji', // 进入企业（璇玑）
  // 会员自有供应商
  supplierLogin: '/elebuys/right/login/login',

  // 盘古运营端
  paguLogin: '/admin/right/login/login',
  paguCheckUser: '/admin/right/login/forgetPwdStep1',
  paguChangePwd: '/admin/right/login/forgetPwdStep2',

  // 分销商城登录
  loginAccountMall: '/bestgift/mall/user/login/mobile/password', // 输入【账号密码】点击确定调用接口
  employeeInfoMall: '/bestgift/mall/user/login/user-list', // 查询当前用户【绑定的员工及企业】接口
  loginCompanyMall: '/bestgift/mall/user/login/enter-mall', // 确认企业》点击》进入【调用】
  mobileSmsCodeMall: '/bestgift/mall/user/login/mobile/sms-code', // 输入手机号》发送验证码》输入验证码》点击确定调用
  usersUpdatepwdMall: '/bestgift/mall/user/login/change-password', // 分销商城修改密码

  // 自定义-妙货
  customEmployeeMall: '/elebuys/user/login/v2/employee-info-miaohuo',// 查询当前用户绑定的全部员工及企业信息 - 妙货
  entetConpanyMall: '/elebuys/user/login/v2/enter-company-miaohuo', // 进去企业

  // 子供应商
  supGetMobile: '/bestgift/ownSupplier/account/mobile/by-account',
  supSendSms: '/bestgift/ownSupplier/account/resetPwd/sendSms',
  supResetPwd: '/bestgift/ownSupplier/account/resetPwd/by-account',
  getSupplierValid: '/bestgift/ownSupplier/account/info/by-account',

  // 猫超
  mcEmployeeInfo: '/elebuys/user/login/v2/employee-info-mcdxb', // 查询当前用户绑定的员工及企业信息 - 猫超
  mcEnterCompany: '/elebuys/user/login/v2/enter-company-mcdxb', // 进入企业 - 猫超
};
