import React from 'react';
import Layout from '@/layout';
import { Navigate } from 'react-router-dom';
import type { RoutesType } from '@fle-ui/plus-router';

export const RouterConfig: RoutesType = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/login',
        component: () => import('@/pages/login'),
      },
      {
        path: '/login/:code',
        component: () => import('@/pages/login'),
      },
      {
        path: '/register',
        component: () => import('@/pages/register'),
      },
      {
        path: '/register/:code',
        component: () => import('@/pages/register'),
      },
      {
        path: '/mobile-register',
        component: () => import('@/pages/mobile-register'),
      },
      {
        path: '/',
        element: <Navigate to="/login" replace />,
      },
    ],
  },
];

export default RouterConfig;
